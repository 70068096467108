// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-neuro-academy-course-registration-tsx": () => import("./../../../src/pages/neuro-academy/course-registration.tsx" /* webpackChunkName: "component---src-pages-neuro-academy-course-registration-tsx" */),
  "component---src-pages-neuro-academy-credits-tsx": () => import("./../../../src/pages/neuro-academy/credits.tsx" /* webpackChunkName: "component---src-pages-neuro-academy-credits-tsx" */),
  "component---src-pages-neuro-academy-index-tsx": () => import("./../../../src/pages/neuro-academy/index.tsx" /* webpackChunkName: "component---src-pages-neuro-academy-index-tsx" */),
  "component---src-pages-neuro-academy-individuals-and-families-tsx": () => import("./../../../src/pages/neuro-academy/individuals-and-families.tsx" /* webpackChunkName: "component---src-pages-neuro-academy-individuals-and-families-tsx" */),
  "component---src-pages-neuro-academy-organizations-tsx": () => import("./../../../src/pages/neuro-academy/organizations.tsx" /* webpackChunkName: "component---src-pages-neuro-academy-organizations-tsx" */),
  "component---src-pages-neuro-plan-app-tsx": () => import("./../../../src/pages/neuro-plan-app.tsx" /* webpackChunkName: "component---src-pages-neuro-plan-app-tsx" */),
  "component---src-templates-blog-listing-tsx": () => import("./../../../src/templates/blog-listing.tsx" /* webpackChunkName: "component---src-templates-blog-listing-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

